import React from "react"
import styled from "styled-components"

import QuoteImage from "../images/timberline-customer-service.jpg"
import ContactForm from "./ContactForm"

const SectionStyles = styled.div`
  .section-container {
    height: 500px;
    position: relative;
    overflow: hidden;

    @media (max-width: 1750px) {
      height: 600px;
    }

    @media (max-width: 1015px) {
      height: 800px;
    }

    @media (max-width: 380px) {
      height: 930px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      filter: grayscale(50%);

      @media (max-width: 1750px) {
        height: 600px;
      }

      @media (max-width: 1015px) {
        height: 800px;
      }

      @media (max-width: 380px) {
        height: 930px;
      }
    }
  }

  .overlay {
    z-index: 1;
    position: absolute;
    width: 100%;
    height: 500px;
    background-color: rgba(0, 0, 0, 0.7);
    @media (max-width: 1750px) {
      height: 600px;
    }
    @media (max-width: 1015px) {
      height: 800px;
    }
    @media (max-width: 380px) {
      height: 930px;
    }
  }

  .form-container {
    position: absolute;
    top: 5%;
    left: 50%;
    z-index: 2;
    text-align: center;
    color: white;
    width: 1000px;
    margin-left: -500px;

    @media (max-width: 875px) {
      width: 800px;
      margin-left: -400px;
    }

    @media (max-width: 670px) {
      width: 600px;
      margin-left: -300px;
    }

    @media (max-width: 500px) {
      width: 500px;
      margin-left: -250px;
    }

    @media (max-width: 380px) {
      width: 400px;
      margin-left: -200px;
    }

    form {
      /* width: 70%; */
      margin-left: auto;
      margin-right: auto;

      input {
        margin: 15px;
        padding: 5px;
        width: 200px;

        @media (max-width: 1015px) {
          width: 80%;
          margin: 10px;
          /* margin-left: 0;
          margin-right: 0; */
        }
      }

      select {
        padding: 7px;
        width: 200px;
        margin: 15px;
        color: gray;
        @media (max-width: 1015px) {
          width: 80%;
          margin: 10px;
          margin-left: 0;
          margin-right: 0;
        }
      }

      textarea {
        width: 75%;
        height: 120px;
        margin-top: 20px;
        margin-left: auto;
        margin-right: auto;

        @media (max-width: 1015px) {
          width: 80%;
          margin-top: 15px;
          margin-left: 0;
          margin-right: 0;
        }
      }

      button {
        margin-top: 15px;
        padding: 15px;
        font-size: small;
        border: none;
        color: white;
        background-color: #5595f7;
        width: 200px;
      }
    }
  }
`

const IndexSection3 = props => {
  return (
    <SectionStyles>
      <div className="overlay"></div>
      <div className="section-container">
        <img src={QuoteImage} alt="" />
        <div className="form-container">
          <h2 style={{ margin: "0", padding: "1rem" }}>{props.title}</h2>
          <p style={{ padding: "0", margin: "0" }}>* denotes required field</p>
          <ContactForm />
        </div>
      </div>
    </SectionStyles>
  )
}

export default IndexSection3
